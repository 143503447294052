<template>
  <div class="about">
    <h1>About InternetNZ</h1>

    <h2><span class="h3">Who are we?&nbsp;</span></h2>
    <p>
      <span style="font-weight: 400"><span>Check out this video to see what we're all about!</span></span>
    </p>
    <p>
      <iframe
        src="https://www.youtube.com/embed/Wtt_iwDayCE?enablejsapi=1&amp;origin=https%3A%2F%2Finternetnz.nz"
        data-gtm-yt-inspected-31571604_28="true"
        id="491370359"
        width="560"
        height="315"
        frameborder="0"
      ></iframe>
    </p>
    <p>
      <span style="font-weight: 400"
        >InternetNZ is the home and guardian for the .nz domain, and it’s our mission to create an Internet for all New
        Zealanders that is safe, accessible and a place for good. A non-profit organisation, we provide the
        infrastructure, security and support to keep the Internet of New Zealand humming.&nbsp;&nbsp;</span
      >
    </p>
    <p>&nbsp;</p>
    <h2><span class="h3">What do we do?</span></h2>
    <p>
      <span style="font-weight: 400"
        >Our role as the guardian of the .nz domain means we’re involved in a lot of Internet-related work throughout
        New Zealand, funded by the sales of .nz domain names. This includes:</span
      >
    </p>
    <ul>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Policy work on Internet issues faced in New Zealand</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Providing community grants to support Internet-related projects</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Conducting research to highlight the state of our Internet</span>
      </li>
      <li style="font-weight: 400">
        <span style="font-weight: 400">Hosting events, such as NetHui, to bring together our Internet community</span>
      </li>
    </ul>
    <h2>
      <span class="h3"><br />Our vision and purpose</span>
    </h2>
    <h3><span class="h5">Our vision</span></h3>
    <p><span style="font-weight: 400">An Internet that is open, secure, and for all New Zealanders.</span></p>
    <h3><span class="h5">Our purpose&nbsp;</span></h3>
    <p><span style="font-weight: 400">Helping New Zealanders harness the power of the Internet.</span></p>
    <h3><span class="h5">Our values</span></h3>
    <p>
      <strong>We have each other’s back.<br /></strong
      ><span style="font-weight: 400"
        >We all bring something important to the table. We want people in our team to have a strong sense of belonging.
        Everyone has a place. We are all here on merit and bring different perspectives from our backgrounds to what we
        do. We respect each other’s abilities; using our complimentary skills to make us more effective. Ultimately,
        that makes us a more formidable and cohesive team.<br
      /></span>
    </p>
    <p>
      <strong>We’re constantly curious.<br /></strong
      ><span style="font-weight: 400"
        >We work in a fast-paced world and things are changing rapidly around us. We keep watch on what’s going on in
        our sector, and explore new ideas as we come across them. Because of our institutional knowledge and capability,
        we can act quickly and with purpose. We are rigorous and commit only when we have the evidence to support doing
        something, or things stack up well. We adapt when we need to and always look for opportunities to learn and
        improve.</span
      >
    </p>
    <p>
      <strong>We all have a part to play.<br /></strong
      ><span style="font-weight: 400"
        >We’re all on this journey and the only way to get there is together. We’ve all got a part to play in keeping up
        our momentum. We’ve got a really good idea of where we’re heading, and there are some things we’ll continue to
        work out along the way. When we get wins and reach milestones, we celebrate them. When things don’t go so well
        we’ll take the learnings and keep on keeping on.</span
      >
    </p>
    <p>
      <span style="font-weight: 400"
        ><strong>We walk the talk.</strong><br />We’re open and transparent. We have a big responsibility and take what
        we do seriously. We make sure we do as we say we do. We’ll always work in the best interests of the community we
        serve. We see ourselves as Kaitiaki (guardians) of a mission-critical sector; one that enables New Zealand Inc
        to do well. We act ethically and with integrity.<br
      /></span>
    </p>
    <p>&nbsp;</p>
    <h2><span class="h3">Our whakataukī</span></h2>
    <p>
      <span style="font-weight: 400">In January 2017, we were delighted to announce that </span
      ><span style="font-weight: 400"
        >Takawai Murphy from Ngāti Manawa, Ngāti Rangitihi and Ngāti Hinekura gifted us our whakatauki.&nbsp;</span
      >
    </p>
    <p>
      <span style="font-weight: 400"
        >A whakataukī is a Māori proverb or saying embodying the values, wisdom, wit, and common-sense of tangata whenua
        (people of the land). They are usually short and concise, using symbolism and metaphor to convey key
        messages.</span
      >
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Kua rāranga tahi tātou he whāriki ipurangi mō āpōpō</strong></p>
    <p>
      <span style="font-weight: 400">Together we weave the mat, in terms of the Internet, for future generations.</span>
    </p>
    <p>&nbsp;</p>
    <p>
      <span style="font-weight: 400"
        >We will use our gifted whakataukī where appropriate in organisation reports, speeches and both internal and
        external information.</span
      >
    </p>
  </div>
</template>
