<template>
  <h1>Why using <a href="https://pages.cloudflare.com/" target="_blank">Cloudflare Pages?</a></h1>

  <ul>
    <li>
      Developer-focused with effortless Git integration.
      <ul>
        <li>git push — it will build and deploy for you.</li>
      </ul>
    </li>
    <li>
      Advanced collaboration built-in with unlimited seats.
      <ul>
        <li>additional collaborators shouldn’t break the bank. With Pages, you can add them all for free.</li>
      </ul>
    </li>
    <li>
      Unmatched performance on Cloudflare’s edge network
      <ul>
        <li>a unique URL is generated by project</li>
        <li>automatically generated links for every commit make it easy to get feedback on the final result</li>
        <li>granular control over who’s accessing your previews</li>
      </ul>
    </li>
    <li>Dynamic functionality through integration with Cloudflare Workers.</li>
  </ul>

  <hr />
  <p>Links:</p>

  <ol>
    <li><a href="https://pages.cloudflare.com/" target="_blank">Cloudflare Pages</a></li>
    <li><a href="https://developers.cloudflare.com/pages/" target="_blank">Developers documentation</a></li>
  </ol>
</template>

<script>
export default {
  name: 'Why',
};
</script>
